<template>
  <div class="container container-table" style="min-height: 100vh;">
    <div class="row">
      <div class="container">
        <div style="max-width:18em; margin: 0 auto;">
          <a href="https://www.edshed.com/" class="edshedlogo">
            <img src="/img/logo-edshed.png" alt="Logo" class="img-responsive center-block">
          </a>
        </div>
        <!-- <div class="center-block" style="width: fit-content; text-align: center">
          <img src="/img/logo.png" class="inline-block sublogo">
          <img v-if="$route.query.locale == 'en_US'" src="/img/math-shed-logo-dark.png" class="inline-block sublogo mathslogo">
          <img v-else src="/img/maths-shed-logo-dark.png" class="inline-block sublogo mathslogo">
        </div> -->
        <!-- <img src="/img/Bee.svg" alt="Logo" class="img-responsive center-block logo"> -->
        <div class="text-center box box-white content" style="margin-top: 50px;">
          <h1>The Teacher Hub has been closed. All functions are now within the EdShed site at <a href="https://www.edshed.com">www.edshed.com</a>.</h1>
          <p><a class="button is-link" href="https://www.edshed.com/">Home</a></p>
          <p>
            <router-link v-if="$store.state.user" to="/logout">
              Logout
            </router-link>
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="container">
        <div class="text-center col-md-12">
          <p style="font-size:14px; color: #4a4a4a;">
            Copyright &copy; {{ new Date().getFullYear() }} Education Shed Ltd.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoAccess'
}
</script>

<style scoped>
  body {
    background-image: url('/public/img/skyBackground.jpg');
    background-size: cover;
    background-repeat:no-repeat;
    background-color: #b4e3f7;
    background-color: #f8f8f8;
  }
</style>
